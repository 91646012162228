<template>
  <div style="padding-bottom: 200px">
    <form
      @submit.prevent="submitHandler"
      v-if="!isLoading"
    >
      <div class="formgrid grid pb-3">
        <!-- Header Section -->
        <div class="field col-6">
          <h2>Recurrence detail</h2>
        </div>
        <div class="field col-6">
          <div class="p-inputgroup justify-content-end">
            <AInputSwitch
              v-model="active"
              class="green"
            />
          </div>
        </div>
        <Divider class="m-2 mb-5"></Divider>

        <div class="formgrid grid">
          <!-- Job Name -->
          <div class="field col-6">
            <label for="jobName">Job Name</label>
            <div class="p-inputgroup">
              <InputText
                id="jobName"
                v-model="recurring.jobName"
                placeholder="Job Name"
              />
            </div>
            <div
              v-if="submitted && !recurring.jobName"
              class="error-validation-text pt-1"
            >
              Job name is required
            </div>
          </div>
          <div class="field col-6">
            <div class="p-inputgroup pt-4">

              <div class="font-light font-smaller">
                <span style="font-weight:600">Use dynamic naming variables<br /></span>

                include {month}, {year}, or {client} variables in the Jon name for auto period naming.
              </div>
            </div>
          </div>

          <!-- Job Template -->
          <div class="field col-6">
            <label for="jobTemplate">Use Job Template</label>
            <div class="p-inputgroup">
              <Dropdown
                id="jobTemplate"
                :options="jobTemplates"
                optionLabel="label"
                optionValue="value"
                v-model="recurring.jobTemplate"
                placeholder="Select Template"
              />
            </div>
            <div
              v-if="submitted && !recurring.jobTemplate"
              class="error-validation-text pt-1"
            >
              Job template is required
            </div>
          </div>
          <div class="field col-6">
            <div class="p-inputgroup">

              <div class="font-light font-smaller pt-2">
                <span style="font-weight:600">Examples:</span>

                <li class="pt-2 pl-2"> {client} {month+1} Retainer</li>
                <li class="pt-2 pl-2">ANC {year} Hosting</li>
                <li class="pt-2 pl-2">{month} Content Calendar</li>

              </div>
            </div>
          </div>

          <div class="field col-6">
            <label for="jobTemplate">Client</label>
            <div class="p-inputgroup">
              <Dropdown
                id="jobTemplate"
                :options="clients"
                optionLabel="label"
                optionValue="value"
                v-model="recurring.client"
                placeholder="Select Template"
              />
            </div>
            <div
              v-if="submitted && !recurring.client"
              class="error-validation-text pt-1"
            >
              Job template is required
            </div>
          </div>
          <div class="field col-6"></div>
          <!-- Repeat Every -->
          <div class="field col-6">
            <label for="repeatEvery">Repeat Every</label>
            <div class="p-inputgroup">
              <InputNumber
                id="repeatEvery"
                v-model="recurring.repeatValue"
                min="1"
                max="12"
                class="w-1"
              />
              <Dropdown
                :options="repeatIntervals"
                v-model="recurring.repeatInterval"
                optionLabel="label"
                optionValue="value"
                class="w-8"
                placeholder="Interval"
              />
            </div>
          </div>

          <!-- Notify Account Executive -->
          <div class="field col-6 align-content-center  align-items-center pt-5">
            <div class="field-checkbox">
              <Checkbox
                id="notifyAE"
                v-model="recurring.notifyAccountExecutive"
                :binary="true"
              />
              <label
                for="notifyAE"
                class="ml-2"
              >Notify Account Executive</label>
            </div>
          </div>

          <!-- Ends Section -->
          <div class="field col-6 align-content-center">
            <label>Ends</label>
            <div class="field-checkbox pt-2">

              <Checkbox
                inputId="never"
                value="never"
                v-model="recurring.endOption"
              />
              <label for="never">Never</label>

            </div>
          </div>
          <div class="col-6"></div>
          <div class="field col-6 align-content-center">
            <div
              class="field-checkbox"
              style="height:40px"
            >
              <Checkbox
                inputId="on"
                value="on"
                v-model="recurring.endOption"
              />
              <label for="on">On</label>
              <Calendar
                v-model="recurring.endDate"
                v-if="recurring.endOption.includes('on')"
                :showIcon="true"
                placeholder="Select Date"
                class="ml-3"
              />
            </div>
          </div>
          <div class="col-6"></div>
          <div class="field col-6 align-content-center">
            <div
              class="field-checkbox"
              style="height:40px"
            >
              <Checkbox
                inputId="after"
                value="after"
                v-model="recurring.endOption"
              />

              <label for="after">After</label>
              <InputNumber
                v-model="recurring.endOccurrences"
                class=" ml-3 input-w-50"
                min="1"
                v-if="recurring.endOption.includes('after')"
              />

              <label
                class="ml-2"
                v-if="recurring.endOption.includes('after')"
              >Occurrences</label>
            </div>

          </div>

        </div>
      </div>
      <div class="field col-12"></div>

      <!-- Buttons -->
      <div class="flex justify-content-between mt-4">
        <Button
          type="submit"
          label="Save"
        />
        <Button
          type="button"
          label="Cancel"
          class="p-button-text p-button-secondary"
          @click="clearForm"
        />
      </div>
    </form>
    <RocketLoad
      v-else
      :full="true"
    />
  </div>
</template>
  
  <script>
export default {
  name: "RecurrenceSlider",

  data() {
    return {
      submitted: false,
      isLoading: false,
      recurring: {
        jobName: "",
        jobTemplate: null,
        repeatValue: 1,
        repeatInterval: null,
        endOption: ["never"],
        endDate: null,
        endOccurrences: 1,
        notifyAccountExecutive: false,
      },
      repeatIntervals: [
        { label: "Day(s)", value: "days" },
        { label: "Week(s)", value: "weeks" },
        { label: "Month(s)", value: "months" },
        { label: "Year(s)", value: "years" },
      ],
      jobTemplates: [
        { label: "Monthly SEO Retainer (Full)", value: "seo" },
        { label: "Content Calendar", value: "content" },
      ],
      clients: [
        { label: "Client 1", value: "client1" },
        { label: "Client 2", value: "client2" },
      ],
    };
  },
  methods: {
    submitHandler() {
      this.submitted = true;
      if (!this.recurring.jobName || !this.recurring.jobTemplate) {
        // Handle errors
        return;
      }
      // Submit form
      console.log("Form submitted:", this.recurring);
    },
    clearForm() {
      this.recurring = {
        jobName: "",
        jobTemplate: null,
        repeatValue: 1,
        repeatInterval: null,
        endOption: "never",
        endDate: null,
        endOccurrences: null,
        notifyAccountExecutive: false,
      };
      this.submitted = false;
    },
  },
  watch: {
    "recurring.endOption": function (val) {
      if (val?.length > 1) {
        this.recurring.endOption = [val[val.length - 1]];
      }
    },
  },
};
</script>
  